<template>
  <v-container hei>
    <v-row justify="center">
      <v-col cols="12" class="text-center py-16">
        <v-sheet height="50vh" class="align-content-center">
          <h1>{{ t('search_no_results') }}</h1>
          <v-btn color="primary" class="mt-16" :to="'/'" rounded="pill" width="200">
            {{ t('back_to_home') }}
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
const { t } = useI18n()
</script>
